.main__header-left{
    text-align: left;
}
.client-onboarding{
    margin-top: 70px;
}
.clients-page {
    padding: 20px;

}
.client-onboarding, .key-partners, .testimonials {
    margin-bottom: 40px;
    text-align: center;
}
.client-onboarding h1, h2 {
    margin-bottom: 10px;
}  
.text{
    width: 600px;
    text-align: left;
    /* margin-bottom: 20px; */
}
.onboarding-steps {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.onboarding-steps .card{
    border-radius: 20px 20px 0 0;
    height: 250px;
} 
.card p{
    text-align: center;
} 
.card h3 {
    margin: 12px 0;
}
.key-partners{
    margin-top: 100px;
} 
 /*New-Part*/
 .partner-logo img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0 10px; 
}
.partner__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
  .partner__logo div{
    margin-left: 5px;
  }

 .subsec{
    width: 80%;
    margin: 40px auto;
 } 
@media screen and (max-width: 500px) {
    .text{
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .section__head h2 {
        text-align: center;
        width: 100%;
    } 
  }
 