.platform-development {
    margin: 70px 0;
    padding: 20px;
}
.text {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}
.text p {
    max-width: 600px;
    margin: 10px auto;
    line-height: 1.6;
    font-size: 18px;
}
.key-features {
    max-width: 70%;
    margin: 60px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 80px;
    list-style-type: none;
    padding: 0;
}
.key-features li {
    max-width: 400px;
    margin: 10px auto;
} 
.key-features strong {
    display: block;
    margin-bottom: 5px;
} 
.visuals-demo {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}
.visuals-demo h2 {
    max-width: 160px;
    margin: 30px auto;
}
.screenshots {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}
.screenshots img {
    max-width: 200px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
} 
.btn {
    background-color: var(--sl-color-btn);
    padding: 16px 32px;
    color: var(--sl-color-black);
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    transition: var(--transition);
    display: inline-block;
    text-align: center;
}
.btn:hover {
    background-color: var(--sl-color-btn-low);
    color: var(--sl-color-gray-2);
}

/* ---------- RESPONSIVE DESIGN ---------- */
@media screen and (max-width: 1024px) {
    .key-features {
        max-width: 80%;
        gap: 60px;
    }
}

@media screen and (max-width: 800px) {
    .key-features {
        max-width: 100%;
        gap: 40px;
    }
    .key-features li {
        max-width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .text p {
        max-width: 90%;
        font-size: 16px;
    }
    .key-features {
        width: 90%;
        grid-template-columns: 1fr;
        gap: 30px;
        margin: 0 auto;
    }
    .key-features li {
        max-width: 100%;
    }
    .visuals-demo h2 {
        max-width: 100%;
        margin: 20px auto;
    }
}

@media screen and (max-width: 500px) {
    .text p {
        width: 100%;
        font-size: 15px;
    }
    .key-features li {
        width: 100%;
    }
    .screenshots img {
        max-width: 180px;
    }
}

@media screen and (max-width: 400px) {
    .text p {
        width: 100%;
    }
    .key-features li {
        width: 100%;
    }
}
