/* ---------- GENERAL STYLING ---------- */
section {
    margin-top: 100px;
    padding: 20px;
    text-align: center; /* Centers all content */
}
.section__head {
    width: 100%;
}
.section__head h2 {
    color: var(--sl-color-gray-1);
    font-size: 32px;
    margin-bottom: 10px;
}
.section__head p {
    color: var(--sl-color-white);
    max-width: 700px;
    margin: 10px auto;
    padding: 10px 20px;
    font-size: 18px;
}

/* ---------- COMPANY OVERVIEW ---------- */
.company-overview-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    text-align: center;
}
.company-overview-img p {
    max-width: 600px;
    font-size: 18px;
    line-height: 1.6;
    margin: 0 auto;
}
.company-overview-img img {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    display: block;
    margin: 0 auto;
}

/* ---------- KEY FEATURES ---------- */
.key-features {
    max-width: 70%;
    margin: 60px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 80px;
    list-style-type: none;
    padding: 0;
}
.key-features li {
    max-width: 400px;
    margin: 10px auto;
} 
.key-features strong {
    display: block;
    margin-bottom: 5px;
} 

/* ---------- VISUAL DEMO SECTION ---------- */
.visuals-demo {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}
.visuals-demo h2 {
    max-width: 160px;
    margin: 30px auto;
}
.screenshots {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
}
.screenshots img {
    max-width: 200px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
} 

/* ---------- BUTTON STYLING ---------- */
.btn {
    background-color: var(--sl-color-btn);
    padding: 16px 32px;
    color: var(--sl-color-black);
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    transition: var(--transition);
    display: inline-block;
    text-align: center;
}
.btn:hover {
    background-color: var(--sl-color-btn-low);
    color: var(--sl-color-gray-2);
}

/* ---------- SERVICES SECTION ---------- */
.services__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

/* ---------- FOOTER ---------- */
.footer-logo {
    width: 60px;
    margin: 0 auto;
}
.footer-logo img {
    width: 100%;
}
footer {
    background: var(--sl-color-gray-4);
    color: var(--sl-color-gray-2);
    width: 100%;
    margin-top: 80px;
    padding: 40px;
    font-size: 16px;
}
.footer__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    text-align: center;
}
.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.footer__container article p {
    font-size: 16px;
    color: var(--sl-color-black);
}
.footer-socials {
    display: flex;
    gap: 12px;
    justify-content: center;
}
.footer-socials a {
    background: #14141486;
    color: var(--sl-color-white);
    padding: 8px 14px;
    border-radius: 10px;
    transition: var(--transition);
}
.footer-socials a:hover {
    background: var(--sl-color-gray-3);
}
.footer__copyright p {
    margin-top: 30px;
    border-top: 2px solid var(--sl-color-gray-2);
    text-align: center;
    padding: 15px 0;
}

/* ---------- RESPONSIVE DESIGN ---------- */
@media screen and (max-width: 1024px) {
    .section__head p {
        width: 75%;
    }
    .company-overview-img img {
        max-width: 400px;
    }
    .footer__container {
        grid-template-columns: 1fr 1fr;
    }
    .key-features {
        max-width: 80%;
        gap: 60px;
    }
}

@media screen and (max-width: 800px) {
    .key-features {
        max-width: 100%;
        gap: 40px;
    }
    .key-features li {
        max-width: 90%;
    }
}

@media screen and (max-width: 768px) {
    section {
        margin-top: 80px;
    }
    .section__head p {
        width: 85%;
    }
    .company-overview-img {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .company-overview-img img {
        max-width: 90%;
        border-radius: 20px;
    }
    .key-features {
        width: 90%;
        grid-template-columns: 1fr;
        gap: 30px;
        margin: 0 auto;
    }
    .footer__container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 650px) {
    .text p {
        max-width: 90%;
        font-size: 16px;
    }
    .visuals-demo h2 {
        max-width: 100%;
        margin: 20px auto;
    }
}

@media screen and (max-width: 500px) {
    .section__head h2 {
        font-size: 26px;
    }
    .section__head p {
        width: 90%;
        font-size: 16px;
    }
    .company-overview-img {
        flex-direction: column;
        gap: 10px;
    }
    .company-overview-img img {
        max-width: 95%;
    }
    .footer-logo {
        width: 55px;
    }
    .footer__container {
        grid-template-columns: 1fr;
    }
    .footer__copyright p {
        margin-top: 20px;
        font-size: 14px;
    }
    .screenshots img {
        max-width: 180px;
    }
}

@media screen and (max-width: 400px) {
    .text p {
        width: 100%;
    }
    .key-features li {
        width: 100%;
    }
    .footer__container article p {
        font-size: 15px;
    }
    .footer__container article {
        text-align: center;
    }
}
