.blog__container{
    margin-top: 60px;
  
}
.blog__text{
 text-align: center;
}
.onboarding-steps {
    margin-top: 60px;
    flex-wrap: nowrap;
}

.blog__container {
    padding: 20px 10px;
  }
  
.blog__card {
    width: 400px;
    padding: 0;   
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}
.blog__card:hover{
    transform: translateY(0);
}
.blog__item h3{
  margin-bottom: 20px;
}

.blog__card img {
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.3s ease;
  }
  .blog__card:hover img {
    transform: scale(1.1); 
  }
  .blog__content {
    padding: 5px;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5); 
    color: #fff;
    text-align: left;
  }
  
  .blog__content p {
    margin:120px 0 20px;
    font-size: 16px;
    text-align: left;
  }
  .subscription-section {
    margin-top: 80px;
    text-align: center;
  }
  
  .subscription-section h2 {
    margin-bottom: 10px;
  }
  
  .subscription-section p {
    margin-bottom: 20px;
  }
  
  .subscription-form {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .subscription-form input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
    max-width: 300px;
  }
  
  .subscription-form .btn {
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .back-arrow {
    margin-bottom: 20px;
    font-weight: bold;
    display: inline-block;
    color: #007bff;
  }
  
  .back-arrow:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .back-arrow span{
    font-size: 19px;
    cursor: pointer;
  }

  .blog-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-details__image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog-details h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  
  .blog-details p {
    font-size: 19px;
    line-height: 2.5;
  }
  
@media screen and (max-width: 800px) {
    .onboarding-steps {
        flex-wrap: wrap;
    }
    .subscription-form {
        flex-direction:column;        
    }
    .subscription-form .btn {
        width: 80%;
        height: 80%;
      }
      .subscription-form input {
        width: 100%;
      }
      .blog-details h1 {
        font-size: 20px;
            }
     .blog-details p {
              font-size: 16px;
          }
}
