/* ---------- MAIN HEADER STYLING ---------- */
.main__header {
    width: 100%;
    display: grid;
    place-items: center;
    margin-top: 48px;
}
.main__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    max-width: 1200px;
    width: 90%;
}
.main__header-left {
    flex: 1;
    margin: 30px 0 0 50px;
}
.main__header-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main__header-left h1 {
    max-width: 540px;
    font-size: 36px;
}
.main__header-left h1 span {
    color: var(--list-active-color);
}
.main__header-left p {
    max-width: 460px;
    margin: 10px 0 40px;
    font-size: 18px;
}
.main__header-left .btn {
    display: inline-block;
    text-align: center;
    background-color: var(--sl-color-btn);
    padding: 16px 32px;
    color: var(--sl-color-black);
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    transition: var(--transition);
}
.btn:hover {
    background-color: var(--sl-color-btn-low);
    color: var(--sl-color-gray-2);
}

/* ---------- HEADER IMAGE ---------- */
.main__header-image img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 20px;
}

/* ---------- PARTNERS LOGOS ---------- */
.main-partners-logo {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    gap: 50px;
}
.main-partners-logo img {
    max-width: 140px;
    height: auto;
}

/* ---------- RESPONSIVE DESIGN ---------- */
@media screen and (max-width: 1024px) {
    .main__header-container {
        flex-wrap: wrap;
        text-align: center;
    }
    .main__header-left {
        width: 100%;
        margin: 0;
    }
    .main__header-left h1 {
        width: 100%;
        font-size: 32px;
    }
    .main__header-left p {
        width: 80%;
        margin: 10px auto;
    }
    .main__header-right {
        width: 80%;
        margin: 20px auto 0;
    }
    .main__header-right img {
        width: 100%;
        max-width: 400px;
    }
}

@media screen and (max-width: 820px) {
    .main__header-left {
        width: 100%;
        text-align: center;
    }
    .main__header-left h1 {
        font-size: 28px;
    }
    .main__header-left p {
        width: 90%;
        font-size: 16px;
    }
    .main__header-right img {
        max-width: 350px;
    }
}

@media screen and (max-width: 768px) {
    .main__header {
        margin-top: 20px;
    }
    .main__header-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .main__header-left {
        width: 100%;
        text-align: center;
    }
    .main__header-left h1 {
        width: 90%;
        font-size: 24px;
    }
    .main__header-left p {
        width: 85%;
        font-size: 16px;
    }
    .main__header-left .btn {
        padding: 12px 24px;
        font-size: 14px;
    }
    .main__header-right {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .main__header-right img {
        max-width: 320px;
    }
}

@media screen and (max-width: 500px) {
    .main__header-left h1 {
        font-size: 22px;
    }
    .main__header-left p {
        width: 100%;
        font-size: 14px;
    }
    .main__header-left .btn {
        padding: 10px 20px;
        font-size: 12px;
    }
    .main__header-right img {
        max-width: 280px;
    }
    .main-partners-logo {
        width: 100%;
        gap: 30px;
    }
    .main-partners-logo img {
        max-width: 100px;
    }
}

@media screen and (max-width: 390px) {
    .main__header-left h1 {
        font-size: 20px;
    }
    .main__header-left p {
        font-size: 14px;
    }
    .main__header-left .btn {
        padding: 8px 16px;
        font-size: 12px;
    }
    .main__header-right img {
        max-width: 250px;
    }
    .container {
        padding: 0 20px;
    }
}
