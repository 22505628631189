.grid__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 60px;
    padding: 0 50px;
    margin-bottom: 35px;
}
.about__story-text {
    margin-top: 0;
}
.about__story-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.about__story-images .team__img {
    max-width: 350px;
    height: auto;
    border-radius: 8px;
}
.about__story-text .write-up {
    max-width: 100%;
}
.career__development {
    margin-top: 170px;
    text-align: center;
}
.career__development-text p {
    max-width: 500px;
    margin: 10px auto;
}

/* ---------- RESPONSIVE DESIGN ---------- */
@media screen and (max-width: 1024px) {
    .about__story-images .team__img {
        max-width: 250px;
    }
    .grid__container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 900px) {
    .about__story-images .team__img {
        max-width: 180px;
    }
}

@media screen and (max-width: 600px) {
    .about__story-images {
        gap: 10px;
    }
    .career__development-text p {
        max-width: 320px;
    }
}

@media screen and (max-width: 450px) {
    .about__story-images .team__img {
        margin: 60px 0 0;
    }
}

@media screen and (max-width: 400px) {
    .about__story-images .team__img {
        max-width: 150px;
    }
    .career__development {
        margin-top: 0;
    }
    .career__development-text p {
        max-width: 100%;
    }
}
